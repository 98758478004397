import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Page from '../../components/Page'

import GhostSVG from '../../assets/images/ghost-404.svg'
import Helmet from 'react-helmet'
import { colors, media, Heading, Container, spacing } from 'cc-ui-components'
import PopularSearchesSection from '../../components/sections/PopularSearchesSection'
import Header from '../../components/Header'
import IndexLayout from '../../components/layout'

const Description = styled.p`
  color: #373a3c;
  font-size: 9px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  margin: 0;

  a {
    color: ${colors.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Ghost = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;

  svg {
    color: ${colors.primary};
    margin: 0 -140px;
  }

  ${media.sm`
    span {
      font-size: 65px
    }
    svg {
      width: 140px;
      height: 68px;
      margin-bottom: 10px;
    }
  `};
`

const GhostText = styled.span`
  color: ${colors.primary};
  font-size: 250px;
  line-height: 1em;
  display: inline-block;
  margin: -50px 0;
`

const Wrapper = styled.div`
  margin-bottom: 8em;
  ${media.sm`
    padding: 0 2em;
    margin-bottom: 2.5em;
    margin-top: -1em;
  `};
`

const NotFoundPage = () => (
  <IndexLayout>
    <Page>
      <Helmet>
        <title>Page not found | at CodeControl</title>
        <meta name="ROBOTS" content="NOINDEX,NOFOLLOW" />
      </Helmet>
      <Header />
      <Container padingsVertical={[spacing(14)]}>
        <Wrapper>
          <Heading level="2" size="xxl" center trailer={2} leader={4}>
            ERROR: 404 Page Not Found
          </Heading>
          <Ghost>
            <GhostText>4</GhostText>
            <GhostSVG />
            <GhostText>4</GhostText>
          </Ghost>
          <Description>
            Sorry, the page you're looking for cannot be accessed. <br />
            Either check the URL, <Link to="/">go home</Link>, or feel free
            to&nbsp;
            <Link to="/en/get-in-touch">report the issue</Link>.
          </Description>
        </Wrapper>
      </Container>
      <PopularSearchesSection />
    </Page>
  </IndexLayout>
)

export default NotFoundPage
